import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [ 
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: '',
        loadChildren: () => import('./faculty/faculty.module').then(m => m.FacultyModule)
    }, 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
